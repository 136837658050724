.main {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.title {
  text-align: center;
  color: #212529;
  font-size: 2rem;
  font-weight: 500;

  line-height: 2;
}

.date {
  color: grey;
  text-align: center;
}

.image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding: 5px;
}
.imageInternal {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 5px;
}
